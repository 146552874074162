.about-card {
  background-color: #fff;
  border-radius: 0.625rem;
  padding: 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease;
  padding-bottom: 150px;
  
}

.about-text {
  flex: 1;
  padding-right: 20px;
}

.about-image {
  flex: 1;
  text-align: center;
  padding-bottom: 100px;
}

.about-heading {
  font-size: 48px;
  font-weight: bold;
  color: #000000;
}

.about-contact a svg {
  margin-right: 0.5em;
}

.about-contact a span {
  padding-left: 0.5em;
}

.about-body {
  font-size: 16px;
  color: #4c4b4b;
  line-height: 1.6;
}

.professional-experience-list li {
  text-align: left;
  list-style-position: inside;
}

.clickable-text {
  color: black;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.clickable-text:hover {
  text-decoration: none;
  color: darkblue;
}

.skill-category {
  color: black;
}

.skill-category .skill-detail {
  font-weight: bold;
}

.skill-category span {
  font-weight: normal;
}

.about-text p {
  color: #070707;
}

.about-text h3 {
  color: black;
}

.about-text h2,
.about-text h3,
.about-text h4 {
  font-weight: bold;
}


@media (max-width: 768px) {
  .about-card {
    flex-direction: column;
    text-align: center;
  }

  .about-image {
    margin-bottom: 20px;
  }

  .about-text {
    padding-right: 0;
  }
}