.contact-container {
    padding: 40px;
    background-color:white;
  }
  
  .contact-card {
    max-width: 700px;
    margin: auto;
    border: none;
  }
  
  .contact-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-text {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .email-link, .social-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .email-link:hover, .social-link:hover {
    text-decoration: underline;
  }

  