.faq-container {
  max-width: 800px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.faq-container h2 {
  text-align: left;
  font-size: 24px;
  color: #5a5a5a;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 10px;
  margin-bottom: 20px;
}


.faq-question {
  background-color: transparent;
  color: #000;
  padding: 10px 0;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: font-weight 0.3s ease;
}

.faq-question:hover,
.faq-item.open .faq-question {
  font-weight: bold;
}


.faq-answer {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, visibility 0.3s ease;
  padding-top: 0;
  color: #333;
}


.faq-item.open .faq-answer {
  visibility: visible; 
  max-height: 500px; 
  padding-top: 10px;
}

.faq-question::before {
  content: '▶';
  font-size: 10px;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.faq-item.open .faq-question::before {
  transform: rotate(90deg);
}

*, *:before, *:after {
  box-sizing: border-box;
}