footer {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  padding: 10px 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #333;
  text-align: center;
}

footer ul {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

footer li {
  margin: 0 10px;
}

footer a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 10px;
  transition: background-color 0.3s;
}

footer a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
