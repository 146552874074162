.pr-graphs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}

.pr-title{
    padding-top: 100px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
}

.graph-card-bench {
    background-color: white;
    border: 3px solid brown;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 48%; 
    height: 400px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}

.graph-card-squat {
    background-color: white;
    border: 3px solid rgb(248, 6, 148);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 48%;
    height: 400px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}
.graph-card-deadlift {
    background-color: white;
    border: 3px solid red;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 48%; 
    height: 400px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}
.graph-card-run {
    background-color: white;
    border: 3px solid green;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 48%;
    height: 400px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}

.graph-card h3 {
    text-align: center;
    margin-bottom: 15px;
}

.ResponsiveContainer {
    width: 100%;
    height: 250px;
}
@media (max-width: 768px) {
    .graph-card-bench, .graph-card-squat, .graph-card-deadlift, .graph-card-run {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .graph-card-bench, .graph-card-squat, .graph-card-deadlift, .graph-card-run {
        width: 100%;
    }
}