.contact-us-container {
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s ease;
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .contact-us-form {
    width: 50%;
  }
  
  .contact-us-form input,
  .contact-us-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-sizing: border-box;
  }
  
  .contact-us-form button {
    background-image: linear-gradient(to left, #333333, #777777); 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .contact-us-form button:hover {
    background-color: darken(var(--accent-color), 10%);
  }
  
  