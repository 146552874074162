.updates-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100%;
    margin: auto;
    padding: 20px;
  }
  
  .update-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: calc((100% - 40px) / 5); 
    margin-bottom: 10px;
  }
  
  .update-date {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .update-description, .update-route, .update-link {
    display: block;
    margin-bottom: 5px;
  }
  
  .update-link {
    display: inline-block;
    margin-top: 8px;
    color: #007bff;
    text-decoration: none;
  }
  
  .update-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .update-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .update-card {
      width: 100%;
    }
  } 