.ReactTwoJobs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    padding-top: 20px;
}

.fa-icon {
    margin: 0 0.5em; 
}

.padding-buddy{
    padding-bottom: 200px;
}

.job-postings {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.job-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.job-card h2 a {
    color: #007bff;
    text-decoration: none;
    font-size: 20px;
}

.job-card h2 a:hover {
    text-decoration: underline;
}

.job-location, .job-remote {
    font-style: italic;
    color: #555;
    
}

.job-details {
    margin-top: 10px;
}

.job-details h3 {
    margin-top: 0;
    font-size: 20px;
}

.job-you-have ul, .job-nice-to-have ul {
    list-style-type: disc;
    margin-left: 20px;
    font-size: 15px;
}
