
:root {
  --primary-color: blue; 
  --hover-background-color: #f8f8f8;
  --card-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  --text-color: #333;
  --hover-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}


.gym-container {
  max-width: auto;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  padding-bottom: 200px;
}

.gym-grid{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}


.card-maingym {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 200px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  transition: background-color 0.3s, box-shadow 0.3s;
}


.card-maingym:hover {
  background-color: var(--hover-background-color);
  cursor: pointer;
  box-shadow: var(--hover-shadow);
  border-color: var(--primary-color);
}


.card-maingym:active {
  transform: scale(0.98);
}

@media (max-width: 768px) {
  .maingym-card-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .card-maingym {
      width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .card-maingym {
      width: 100%;
  }
}