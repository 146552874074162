.track-food-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
 
 
}

.track-food-container h1 {
  text-align: center;
  color: #333;
}

.label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.select,
.input {
  width: calc(100% - 16px); /* Account for padding */
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-arrow-down-b-512.png")
    no-repeat right center;
  background-size: 20px 20px;
}

.add-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #0056b3;
}

.track-food-container h3 {
  margin-top: 20px;
  color: #333;
}

.track-food-container p {
  margin: 5px 0;
}

.track-food-container h2 {
  margin-top: 20px;
  color: #333;
}

.padding-bottom {
  padding-bottom: 200px;
}

button[type="success"] {
  background-color: green;
}

.meal-cards-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}

.meal-card, .total-intake-card {
  background-color: white;
    border: 2px solid black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 20%; 
    height: auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}


@media (max-width: 768px) {
  .meal-card, .total-intake-card {
      width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .meal-card, .total-intake-card {
      width: 100%;
  }
}