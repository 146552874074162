.projects-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    padding-bottom: 200px;
  }
  
  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .project-card {
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  
  .project-icon {
    width: 100%; 
    height: auto;
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .project-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .project-description {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .project-title-link {
    font-weight: bold;
    margin-bottom: 10px;
    text-decoration: none;
    color: #333;
    position: relative;
    transition: color 0.3s;
}
  
.project-title-link:hover {
  color: #007bff; 
}
  
  @media (max-width: 768px) {
    .project-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .project-card {
      width: 100%;
    }
  }  