.chat-section {
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease;
  width: 300px;
  height: auto;
  margin-bottom: 20px;
}

.chat-container {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 10px;
  box-sizing: border-box;
}

.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.user-message,
.support-message {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.user-message {
  background-color: var(--secondary-color);
  text-align: right;
}

.support-message {
  background-color: #d1e7ff;
  text-align: left;
}

.chat-container input[type="text"] {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.chat-container button {
  background-image: linear-gradient(to left, #333333, #777777); 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.chat-container button:hover {
  background-color: darken(var(--accent-color), 10%);
}

@media (max-width: 768px) {
  .chat-section {
      width: 100%;
      height: auto;
  }
}
