.conference-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.dashboard-title {
  display: flex;
  justify-content: center;
}

.dashboard-sub-title {
  display: flex;
  justify-content: center;
  animation: flashingWarning 5s linear infinite;
  font-weight: bold; 
  font-size: 1.2em;
}

@keyframes flashingWarning {
  0%, 100% {
    color: red; 
    opacity: 1; 
  }
  50% {
    color: black; 
    opacity: 0.5; 
  }
}

/* Base styles for all cards - keep these */
.player-card,
.american-conference-card,
.national-conference-card,
.total-conference-card,
.sales-conference-card {
  width: 200px;
  height: 40px;
  border: 1px solid black;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  border-radius: 10px;
}

/* Modified card classes with new background and text colors */
.american-conference-card,
.american-conference-card.active,
.player-card.american-active {
  background-color: #ff4d4d; /* red */
  color: white;
  border: 3px solid #ff4d4d;
}

.national-conference-card,
.national-conference-card.active,
.player-card.national-active {
  background-color: #4d4dff; /* blue */
  color: white;
  border: 3px solid #4d4dff;
}

.total-conference-card,
.total-conference-card.active,
.player-card.total-active {
  background-color: #808080; /* grey */
  color: white;
  border: 3px solid #808080;
}

.sales-conference-card,
.sales-conference-card.active,
.player-card.sales-active {
  background-color: #038003; /* green */
  color: white;
  border: 3px solid #038003;
}

/* Keep the .players-container styles if they are still relevant */
.players-container {
  display: flex;
  justify-content: center;
  margin: 20px;
  padding-bottom: 70px;
}

/* Adjust the .player-card class if necessary */
.player-card {
  width: 400px;
  height: 200px;
  border: 1px solid black;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  /* Add background and text color if needed for non-active states */
}


.chart-container {
  width: 100%;  
  max-width: 1100px;
  margin: auto; 
  padding-bottom: 110px;
  background-color: #f9f9f9;
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chart-container.financial {
  background-color: #f2f2f2;
}

.financial-charts {
  display: flex;
  justify-content: space-around;
}

.financial-chart {
  width: 50%; 
  padding: 15px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.financial-chart h3 {
  text-align: center;
  margin-bottom: 15px;
  color: #333;
}

