:root {
  --blue: #007bff;
  --blue-dark: #0069d9;
  --gray-light: #f8f9fa;
  --gray-dark: #343a40;
  --border-radius: 5px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: var(--gray-dark);
  background-color: var(--gray-light);
  margin: 0;
  padding: 20px;
}

.main-container {
  padding-bottom: 150px;
}

.btn {
  background-color: var(--blue);
  color: #fff;
  border: none;
  padding: 0.625rem 1.25rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  margin: 5px;
  font-size: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.btn:hover,
.btn:focus {
  background-color: var(--blue-dark);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.main-menu-btn {
  background-color: #76b947;
}

.add-workout-btn {
  background-color: #ff7f50;
}

.save-all-btn {
  background-color: #007bff;
}

.add-set-btn {
  background-color: #ffd700;
  color: #000;
}

.delete-workout-btn {
  background-color: #dc3545;
}

.main-menu-btn:hover,
.main-menu-btn:focus {
  background-color: #689f38;
}

.add-workout-btn:hover,
.add-workout-btn:focus {
  background-color: #e67e22;
}

.save-all-btn:hover,
.save-all-btn:focus {
  background-color: #0056b3;
}

.add-set-btn:hover,
.add-set-btn:focus {
  background-color: #ffdf00;
}

.delete-workout-btn:hover,
.delete-workout-btn:focus {
  background-color: #c82333;
}

.gym-card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  padding: 20px;
  align-items: start;
}

.gym-card {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  padding: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gym-card h4 {
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.gym-card:hover {
  transform: translateY(-5px);
}

input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
  transition: border 0.3s ease;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="text"]:focus,
input[type="number"]:focus {
  border-color: var(--blue);
  outline: none;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .gym-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .gym-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .gym-card-container {
    grid-template-columns: 1fr;
  }
}
