.training-videos-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.videos-grid {
  flex: 0 0 20%;
  overflow-y: auto; 
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
}

.training-video-player {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(to bottom right, #ffffff, #f0f0f0);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.video-and-notes-container {
  display: flex;
  flex-direction: column; 
  flex: 1; 
  padding: 20px;
  box-sizing: border-box;
  padding-bottom: 100px;
  
}

.video-card-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #333;
  transition: transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  animation: fadeInUp 0.5s ease-out forwards;
}

.video-card-container:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.video-card-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-card-img img {
  max-height: 150px;
  width: auto;
  object-fit: contain;
}

.video-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}

.card-title {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: black; 
  font-weight: bold;
}

p {
  margin: 0;
  color: #666;
}

.ratio {
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
  flex-grow: 2;
  padding: 10px;
  background: #f9f9f9;
}

.text-box {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  padding: 200px;
  box-sizing: border-box; 
  border: 3px solid #333;
}

.ratio iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videos-grid::-webkit-scrollbar {
  width: 10px;
}

.videos-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.videos-grid::-webkit-scrollbar-thumb {
  background: #888;
}

.videos-grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .training-videos-container {
    flex-direction: column;
  }

  .videos-grid,
  .training-video-player {
    width: 100%;
    max-width: none;
    padding: 10px;
  }

  .text-box {
    width: calc(100% - 20px);
  }
}
