.stopwatch-container {
  text-align: center;
  margin: 0 auto;
  width: 500px;
}

.stopwatch-display {
  font-size: 36px;
  font-weight: bold;
}

.green-button {
  background-color: green;
  color: white;
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.red-button {
  background-color: red;
  color: white;
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.orange-button {
  background-color: orange;
  color: white;
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.stopwatch-button:hover {
  background-color: #eee;
}
