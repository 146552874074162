.issues-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.issue-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.screenshot-img {
  flex: 0 0 66.66%;
  max-width: 66.66%; 
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.description-card {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  height: auto;
}

.card-body p {
  font-family: 'Arial', sans-serif;
  font-size: 14px; 
  color: #333; 
  text-align: left;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  margin: 8px 0; 
  padding: 12px; 
  border-left: 3px solid #007bff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
  
}

.card-body p:hover {
  color: #007bff; 
  transition: color 0.3s ease-in-out;
}

.padding-buddy{
  padding-bottom: 150px;
}

.app-header {
  background-color: #f8f9fa;
  padding: 20px;
  border-bottom: 2px solid #dee2e6;
  text-align: center;
}

.app-header h2 {
  color: #000000;
  font-size: 24px; 
  margin-bottom: 10px; 
  font-weight: bold;
}

.app-header p {
  color: #000000;
  font-size: 16px;
  margin-bottom: 5px; 
}


button.download-button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #1ecf56; 
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

button.download-button:hover {
  background-color: #45a049; 
}


@media (max-width: 768px) {
  .issue-container {
    flex-direction: column;
  }

  .screenshot-img,
  .description-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .description-card {
    padding: 5px;  }
}