.card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
}

.support-container{
  padding-left: 45px;
}

.card {
  width: 30%;
  height: 50%;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e1e1e1;
  background-color: #fff;
  transition: box-shadow 0.3s ease-in-out;
  background: linear-gradient(to right, #ffffff, #f7f7f7);
  border-radius: 8px;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.card h2,
.card p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: auto;
}

/* Responsive design */
@media (max-width: 768px) {
  .card {
    width: 48%;
    margin: 1%;
  }
}

@media (max-width: 480px) {
  .card {
    width: 98%;
    margin: 1% 0;
  }
}
